
import { Options, Vue } from "vue-class-component";
@Options({
  components: {}
})
export default class TermoEmpresa extends Vue {
  mounted() {
    document.title = 'Termos Volty Empresa';
  }
}
